import {get,put} from '../util/request.js';
import {showErrorTip} from '../util/index.js'
import user from '../js/user';
// 获取课程体系列表
const getDetail = async (data) => {
  let url;
  if(user.userinfo.role==='teacher'||user.userinfo.role==='class_mgmt'){
    url = '/api/TeacherCourseTaskAPI'
  }else{
    url = '/api/CourseTaskAPI'
  }
  const res = await get({
    url,
    data
  });
  if(res.error){
    showErrorTip(res.data);
  }
  return res;
}
// 老师修改某一个任务的状态
const updateTaskStatus = async (data) => {
  let url='/api/TeacherCourseTaskAPI';
  const res = await put({
    url,
    data
  });
  if(res.error){
    showErrorTip(res.data);
  }
  return res;
}

export {
  getDetail,
  updateTaskStatus
}