import {Tag,Select,Option,Input,Button,Pagination,Dialog,Rate,Form,FormItem } from 'element-ui';
import {getDetail,updateTaskStatus} from '../api/task';
import user from '../js/user';
export default {
  name:'index',
  components:{
    elTag:Tag,
    elSelect:Select,
    elOption:Option,
    elInput:Input,
    elButton:Button,
    elPagination:Pagination,
    elDialog:Dialog,
    elRate:Rate,
    elForm:Form,
    elFormItem:FormItem
  },
  data(){
    this.id = this.$route.params.id;
    this.formLabelWidth = '80px';
    return {
      user,
      rate:3,
      showReview:false,
      reviewInfo:{},
      title:'',
      desc:'',
      list:[],
    }
  },
  async mounted(){
   this.getDetail();
  },
  methods:{
    async getDetail(){
      const res = await getDetail({
        course_id:this.id
      });
      if(res.error === null){
        const {course:{course_template:{name,description,id}},task} = res.data;
        this.title = name;
        this.desc = description;
        this.courseid  = id;
        this.list = task;
      }
    },
    async handleClickBtn(type,taskid,submissionid,index){
      // 1 老师开启任务
      if(type === 1){
        const res = await updateTaskStatus({
          "status": "started",
          "task_id": taskid
        });
        if(!res.error){
          this.getDetail();
        }
      }
      // 2 老师查看学生任务
      if(type === 2){ // 学生端跳到作业详情，教师端跳到学生的作业列表
        this.$router.push(`/student-task-status/${taskid}`);
        return ;
      }
      // 3 学生查看评价
      if(type === 3){
        this.showReview = true;
        this.reviewInfo = taskid.submission;
      }
      // 4. 学生开始任务
      if(type === 4){
        window.open(`./scratch3/task.html?taskid=${taskid}`);        
      }
      // 5. 学生查看完成作品
      if(type === 5){
        window.open(`./scratch3/seetask.html?taskid=${taskid}&submissionid=${submissionid}`);
      }
      // 6. 学生查看历史记录
      if(type===6){
        this.$router.push(`/submission-list?id=${taskid}&index=${index}`)
      }
    },
    onModifyTask(taskid,submissionid){
      window.open(`./scratch3/task.html?taskid=${taskid}&submissionid=${submissionid}`);        
    },
    pageToDetail(){
      this.$router.push(`/student-record/${this.id}`);
    }
  }
}